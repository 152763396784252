import Types from './types';

const INITIAL_STATE = {
    user: null,
    isFetching: false,
    error: false,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FETCH_START: return { ...state, isFetching: true }

        case Types.FETCH_SUCCESS: return { ...state, user: {...action.payload}, isFetching: false, error: false }

        case Types.FETCH_ERROR: return { ...state, isFetching: false, error: true }

        default: return state;
    }
}

export default reducer