import colors from 'resources/constants/colors';
import styled from 'styled-components';

export const DefaultInput = styled.input`
  border: none;
  background-color: transparent;
  color: ${colors.kDarkBlue};
  font-weight: 500;
  font-size: 1rem;
  width: 100%;
  &:focus {
    outline: unset;
  }
  ::placeholder {
    opacity: 0.3;
  }
`;