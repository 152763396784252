import React, { useEffect, useState } from 'react';
import CARD_BRANDS from 'resources/constants/cardBrands';
import styled from 'styled-components';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import CardNumber from './CardNumber';
import { BoldText } from 'components/Texts';
import { DefaultInput } from 'components/Inputs';
import Expiration from './Expiration';
import CVC from './CVC';
import { ColorButton } from 'components/Buttons';
import CardIcon from './CardIcon';
import { ErrorText } from 'components/Texts';
import validateAndSubmit from './validate';

const MESSAGE_INVOKER_ID = 'message-invoker-container';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  width: 100%;
  background-color: transparent;
`;

const CardBackground = styled.div`
  background-color: white;
  border: 1px solid black;
  min-width: 200px;
  width: 100%;
  border-radius: 15px;
  padding: 20px 15px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  grid-gap: 5px;
`;

const CardForm = ({ user }) => {
  const [brand, setBrand] = useState(CARD_BRANDS.UNKNOWN);
  const [formData, setFormData] = useState({ name: `${user.name?.stringValue ?? ''}${user.last_name ? ' ' : ''}${user.last_name?.stringValue ?? ''}`, zip: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const onChangeCard = (e) => {
    setBrand(e.brand);
  };

  useEffect(()=>{
    console.log('formData', formData.name, formData.zip);
  }, [formData]);

  const onChange = (key, value) => {
    const obj = { ...formData };
    obj[key] = value;
    setFormData(obj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await validateAndSubmit(formData, user.secret, stripe, elements);
      notifyApp();
    } catch (e) {
      if (e.message !== '') {setError(e.message); return;};
      setError('Error desconocido');
    }

    setLoading(false);
  };

  const notifyApp = () => {
    // eslint-disable-next-line no-undef
    MessageInvoker.postMessage('success'); 
  }

  return (
    <form onSubmit={loading ? () => {} : handleSubmit}>
      <div id={MESSAGE_INVOKER_ID}></div>
      <Container>
        <CardBackground>
          <CardIcon brand={brand} />
          <BoldText>Número</BoldText>
          <CardNumber onChange={onChangeCard} />
          <div style={{ height: '15px' }}></div>
          <BoldText>Nombre en la tarjeta</BoldText>
          <DefaultInput value={formData.name} onChange={(e) => onChange('name', e.target.value)} placeholder={'Alonso Pérez'} required={true} />
          <div style={{ height: '15px' }}></div>
          <Grid>
            <Expiration />
            <CVC />
            <div>
              <BoldText>Código Postal</BoldText>
              <DefaultInput
                id={'postal-code'}
                name={'postal_code'}
                value={formData.zip}
                type={'number'}
                onChange={(e) => onChange('zip', e.target.value)}
                placeholder={'90000'}
                required={true}
              />
            </div>
          </Grid>
        </CardBackground>
        <div style={{ height: '15px' }}></div>
        {error && <ErrorText>{error}</ErrorText>}
        <ColorButton type={'submit'} style={{ width: '80%' }}>
          {loading ? '...' : 'Confirmar'}
        </ColorButton>
      </Container>
    </form>
  );
};

export default CardForm;
