import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useUser from 'state/user';
import CardForm from './CardForm';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_CODE } from 'resources/constants/config';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(STRIPE_CODE);

const Loading = () => <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>Cargando...</div>;

const DataLoader = ({ token, uid }) => {
  const [data, dispatcher] = useUser();

  useEffect(() => {
    dispatcher.fetchStart({ token, uid });
  }, []);

  return !!data.user ? (
    <Elements stripe={stripePromise}>
      <CardForm user={data.user} />
    </Elements>
  ) : (
    <Loading />
  );
};

const NewCard = () => {
  const [token, setToken] = useState(null);
  const [uid, setUID] = useState(null);
  const history = useHistory();

  window.addEventListener(
    'message',
    (e) => {
      if (!e.data || typeof e.data !== 'string' || (!e.data.startsWith('token:') && !e.data.startsWith('uid:'))) {
        return;
      }
      if (e.data.startsWith('token:')) {
        const parse = e.data.slice(6);
        if (parse === '') {
          history.push('error');
        }
        setToken(parse);
        return;
      }
      if (e.data.startsWith('uid:')) {
        const parse = e.data.slice(4);
        if (parse === '') {
          history.push('error');
        }
        setUID(parse);
      }
    },
    false
  );

  return !!token && !!uid ? <DataLoader token={token} uid={uid} /> : <Loading />;
};

export default NewCard;
