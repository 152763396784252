import colors from 'resources/constants/colors';

export const OPTIONS = {
  style: {
    base: {
      color: colors.kDarkBlue,
      fontFamily: "'Montserrat', sans-serif",
      fontSmoothing: 'antialiased',
      fontSize: '1rem',
      '::placeholder': {
        color: colors.kGrey,
      },
    },
    invalid: {
      color: colors.kErrorColor,
      iconColor: colors.kErrorColor,
    },
  },
};
