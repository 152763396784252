import { takeLatest, put, all, call } from 'redux-saga/effects';
import { paymentsAPI, usersAPI } from 'resources/api';
import Types from './types';

function* fetchStartAsync({ payload }) {
  try {
    const res = yield usersAPI.get(payload.token, payload.uid);
    const intent = yield paymentsAPI.setupPayment(payload.token, res.fields?.stripe_client_id?.stringValue);
    console.log('intent', intent);
    yield put({ type: Types.FETCH_SUCCESS, payload: res.fields ? {...res.fields, secret: intent.secret} : {} });
  } catch (e) {
    console.error(e);
    console.log(e.response.data);
    console.log(e.response.status);
    console.log(e.response.headers);
    console.log(e.response.config);
    yield put({ type: Types.FETCH_ERROR });
  }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

export default function* sagas() {
  yield all([
    call(fetchStart),
  ]);
}