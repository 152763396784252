import Axios from "axios";
import { executeCall } from './utils';

const consumer = (domain, resource) => {
  const url = `${domain}/${resource}`;

  console.log('servers', url);
  return {
    setupPayment: (token, s_uid) => executeCall(() => Axios.post(`${url}/setup-payment`, {clientId: s_uid}, { headers: { Authorization: token } })),
  };
}

export default consumer;