import React from 'react';
import { CardCvcElement } from '@stripe/react-stripe-js';
import { OPTIONS } from './styles';
import { BoldText } from 'components/Texts';

const CVC = () => {
  return (
    <div>
      <BoldText>CVC</BoldText>
      <CardCvcElement options={OPTIONS} />
    </div>
  );
};

export default CVC;
