import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  margin: 0;
`;

export const Logo = styled.img`
  position: absolute;
  left: 30px;
  top: 30px;
  width: 200px;
`;

export const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Main = styled.main`
  padding: 10px;
  padding-left: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    flex: 2;
  }
`;

export const Aside = styled.aside`
  flex: 1;
  @media only screen and (max-width: 600px) {
    flex: 3;
  }
`;

export const Picture = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Title = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  margin: 0;
  line-height: 70px;
  max-width: 70%;
  @media only screen and (max-width: 600px) {
    font-size: 2.5rem;
    line-height: 55px;
    max-width: 90%;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
  line-height: normal;
  padding-left: 4px;
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;
