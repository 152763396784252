import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from "pages/home";
import NewCard from 'pages/new-card-stripe';
import Error from 'pages/error';

function App() {
  return (
    <Switch>
      <Route path="/new-card-stripe" component={NewCard} exact={true} />
      <Route path="/error" component={Error} exact={true} />
      <Route path="/" component={Home} exact={true} />
    </Switch>
  );
}

export default App;
