import React from 'react';
import LogoSVG from 'resources/img/name_logo.svg';
import ClassroomIMG from 'resources/img/classroom_background_mid-min.jpg';
import { Container, Logo, Layout, Main, Subtitle, Title, Aside, Picture } from './styles';

const Home = () => {
  return (
    <Container>
      <Logo src={LogoSVG} />
      <Layout>
        <Main>
          <Subtitle>Hola, bienvenido</Subtitle>
          <Title>Estamos en proceso de crear algo nuevo.</Title>
        </Main>
        <Aside>
          <Picture src={ClassroomIMG} />
        </Aside>
      </Layout>
    </Container>
  );
};

export default Home;
