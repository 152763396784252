import colors from 'resources/constants/colors';
import styled from 'styled-components';

export const BaseButton = styled.button`
  width: 100%;
  padding: 10px 0px;
  border: 1px solid ${({ color }) => (color ? color : colors.kDarkBlue)};
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  &:focus {
    outline: 1px solid ${({ color }) => (color ? color : colors.kDarkBlue)};
  }
`;

export const ColorButton = styled(BaseButton)`
  background-color: ${({ color }) => (color ? color : colors.kDarkBlue)};
  color: white;
  &:hover {
    background-color: white;
    color: ${({ color }) => (color ? color : colors.kDarkBlue)};
  }
`;
