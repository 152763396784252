import colors from "resources/constants/colors";
import styled from "styled-components";

export const TitleH1 = styled.h1`
    margin: 0;
    font-weight: 400;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
`;

export const TitleH2 = styled.h2`
    margin: 0;
    font-weight: 600;
    font-size: 1.5rem;
`;

export const TitleH3 = styled.h3`
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
`;

export const TitleH4 = styled.h4`
    margin: 0;
    font-weight: 600;
    font-size: 0.875rem;
`;

export const Body = styled.p`
    margin: 0;
    font-size: 0.875rem;
`;

export const BoldText = styled.p`
    margin: 0;
    font-weight: 600;
    font-size: 0.875rem;
    margin-bottom: 5px;
`;

export const InvalidText = styled.p`
  margin: 0;
  font-size: 0.75rem;
  color: ${colors.kErrorColor};
`;

export const ErrorText = styled(Body)`
    color : ${colors.kErrorColor}
`;