import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  margin: 0;
`;

export const Title = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  margin: 0;
  line-height: 70px;
  max-width: 70%;
  color: black;
  @media only screen and (max-width: 600px) {
    font-size: 2.5rem;
    line-height: 55px;
    max-width: 90%;
  }
`;
