import React from 'react';
import { CardExpiryElement } from '@stripe/react-stripe-js';
import { OPTIONS } from './styles';
import { BoldText } from 'components/Texts';

const Expiration = () => {
  return (
    <div>
      <BoldText>Expiración</BoldText>
      <CardExpiryElement options={OPTIONS} />
    </div>
  );
};

export default Expiration;
