import Axios from "axios";
import { executeCall } from './utils';

const consumer = (domain, resource) => {
  const url = `${domain}/${resource}`;

  console.log('servers', url);
  
  return {
    get: (token, uid) => executeCall(() => Axios.get(`${url}/${uid}`, { headers: { Authorization: `Bearer ${token}` } })),
  };
}

export default consumer;


