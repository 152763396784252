const colors = {
    kDarkBlue: '#0A2E41',
    kMainBlue: '#059AAD',
    kLightBlue: '#0A2E4180',
    kAlternateLightBlue: '#A0B8C6',
    kBackgroundLight: '#F4F4F4',
    kErrorColor: '#F44814',
    kGrey: '#B9B9B9',
    kDarkGrey: '#5C5C5C',
}

export default colors;